export const pathName = {
  accountSettings: '/my-account/info',
  oldAccountSettings: '/settings/account',
  manageWebsites: '/my-account/websites',
  whiteLabelCustomization: '/my-account/white-label-customization',
  agencyCommission: '/my-account/agency-commission',
  agencyWhiteLabel: '/my-account/agency-white-label',
  billing: '/my-account/invoices',
  consent: '/my-account/consent',
  addWebsite: '/my-account/add-website',
  appsumo: '/appsumo',
};

export const tabNames = {
  ecommerce: 'ecommerce',
  ecommerceOverview: 'ecommerceOverview',
  ecommerceCustomers: 'ecommerceCustomers',
  ecommerceOrders: 'ecommerceOrders',
  ecommerceSalesCharts: 'ecommerceSalesCharts',
  overview: 'dashboard',
  latestVisitorsOverview: 'visitorsOverview',
  latestVisitorsList: 'latest',
  visitorsHistory: 'visitorsHistory',
  latestVisitorsMap: 'map',
  latestVisitorsLocations: 'locations',
  trafficCharts: 'traffic',
  pageVisits: 'pageVisits',
  landingPages: 'landingPages',
  clickPaths: 'clickPaths',
  conversions: 'conversions',
  referringSites: 'referringSites',
  referringSitesDetails: 'referringSitesDetails',
  operatingSystems: 'os',
  browsers: 'browsers',
  displayResolution: 'displayResolution',
  adsOverview: 'adsOverview',
  adsLatestVisitors: 'adsLatestVisitors',
  myCampaigns: 'myCampaigns',
  adsCharts: 'adsCharts',
  utmGenerator: 'utmGenerator',
  websiteSettings: 'websiteSettings',
  settings: 'settings',
  generalSettings: 'generalSettings',
  agencyWhiteLabel: 'agencyWhiteLabel',
  subscriptionSettings: 'subscriptionSettings',
  modulesSettings: 'modulesSettings',
  pageSettings: 'pageSettings',
  consent: 'consent',
  agencyCommission: 'agencyCommission',
  websites: 'websites',
  devices: 'devices',
  competition: 'competition',
  heatmaps: 'heatmaps',
  funnels: 'funnels',
  recordings: 'recordings',
  recordingsDetailsPage: 'recordingsDetailsPage',
  export: 'export',
  trackingCode: 'trackingCode',
  import: 'import',
  tools: 'tools',
  chatInbox: 'chatInbox',
  chatAutoMessages: 'chatAutoMessages',
  feedback: 'feedback',
  polls: 'polls',
  surveys: 'surveys',
  myAccount: 'myAccount',
  trafficStructure: 'trafficStructure',
  trafficShare: 'trafficShare',
  trafficSession: 'trafficSession',
  trafficPagesPerVisit: 'trafficPagesPerVisit',
  trafficBounceRate: 'trafficBounceRate',
  privacyCenter: 'privacyCenter',
  milestone: 'milestone',
  eventTracking: 'eventTracking',
  alarmingBehavior: 'alarmingBehavior',
  eventTagGenerator: 'eventTagGenerator',
  manageContributors: 'manageContributors',
  addWebsite: 'addWebsite',
  accountSettings: 'accountSettings',
  visitorSegments: 'visitorSegments',
  webStatsOverview: 'webStatsOverview',
  campaignsOverview: 'campaigns',
  behaviour: 'behaviour',
  websiteErrors: 'websiteErrors',
};

export const subFeaturesGeneralSettings = {
  ignoreSelfVisits: 'ignoreSelfVisits',
  whiteListDomain: 'whiteListDomain',
};

export const tooltipTabs = [tabNames.trafficCharts, tabNames.devices];

export const modalName = {
  //Modals that opens on emailLink click.
  changeEmailConfirmation: 'changeEmailConfirmation',
  resetEmailConfirmation: 'resetEmailConfirmation',
  deleteAccountConfirmation: 'deleteAccountConfirmation',

  //Automatic opening
  firstLogin: 'firstLogin',
  noData: 'noData',
  boostTraffic: 'boostTraffic',
  upgradeToYearly: 'upgradeToYearly',
  installSsr: 'installSsr',
  addWixSsrCode: 'addWixSsrCode',
  recordingsSettings: 'recordingsSettings',
  upgradeUntilPriceRises: 'upgradeUntilPriceRisesModal',
  visitsLimitReached: 'visitsLimitReached',
  announcement: 'announcement',
  welcomeHeatmaps: 'welcomeHeatmaps',
  googleImport: 'googleImport',
  updateSubscription: 'updateSubscription',

  //User interaction opening
  signDPA: 'signDPA',
  upgrade: 'upgrade',
  exportData: 'exportData',
  accountSettingsRequired: 'accountSettingsRequired',
  resetData: 'resetData',
  directAccess: 'directAccess',
  generalError: 'generalError',
  deleteAllRecordings: 'deleteAllRecordings',
  ssrReachedLimit: 'ssrReachedLimit',
  ssrTechnicalLimitation: 'ssrTechnicalLimitation',
  heatmapsWelcomeV2: 'heatmapsWelcomeV2',
  heatmapsLimitReachedV2: 'heatmapsLimitReachedV2',
  heatmapManualScreenshot: 'heatmapManualScreenshot',
  featureVideo: 'featureVideo',

  welcomeFunnel: 'welcomeFunnel',

  featureLimitReached: 'featureLimitReached',
  confirmationModal: 'confirmationModal',
  urlStructureModal: 'urlStructureModal',

  //User interaction opening - only standalone
  changeEmail: 'changeEmail',
  changePassword: 'changePassword',
  checkLogin: 'checkLogin',
  addWebsite: 'addWebsite',
  // @deprecated
  checkTrackingCode: 'checkTrackingCode',
  deleteAccount: 'deleteAccount',
  updateCompanyDetail: 'updateCompanyDetail',
  cancelAgencySubscription: 'cancelAgencySubscription',
  whiteLabelWebsites: 'whiteLabelWebsites',

  tabInformation: 'tabInformation',
  createEventTag: 'createEventTag',
};

export const behaviourCounterTabs = [
  tabNames.recordings,
  tabNames.heatmaps,
  tabNames.funnels,
  tabNames.polls,
  tabNames.surveys,
];

export const SOURCE_TAB = {
  OVERVIEW: 'overview',
  LATEST_VISITORS: 'latestVisitors',
  RECORDINGS: 'recordings',
  CAMPAIGNS: 'campaigns',
};

export const tabsWithFeatureLimit = [
  tabNames.pageVisits,
  tabNames.recordings,
  tabNames.heatmaps,
  tabNames.funnels,
  tabNames.polls,
  tabNames.surveys,
  tabNames.eventTracking,
];

export const panelsPaginationTabNames = [
  tabNames.adsLatestVisitors,
  tabNames.latestVisitorsList,
  tabNames.trafficShare,
  tabNames.recordings,
  tabNames.myCampaigns,
];

export const tabsToPreventScrollToTop = [tabNames.devices, tabNames.overview];

export const reportBlockPaths = {
  includes: [
    '/dashboard',
    '/traffic',
    '/visitors/history/',
    '/behaviour/heatmaps',
    '/ecommerce',
    '/campaigns',
    '/behaviour/event-tracking/alarming-behavior',
  ],
  endsWith: [
    //statistics
    '/statistics/overview',
    '/visitors/latest',
    '/visitors/map',
    '/visitors/charts',
    '/visitors/locations',
    '/visitors/company-visits',
    '/pages/landing',
    '/pages/conversions',
    '/pages/referring',
    '/competition',
    '/devices',

    //behaviour
    '/behaviour/event-tracking/manual',
    '/behaviour/event-tracking/auto',
    '/behaviour/event-tracking',

    '/behaviour/recordings',
    '/behaviour/funnels',

    //communication
    '/polls',
    '/participants',
    '/surveys',

    //account-settings
    '/my-account/websites',
    '/agency-white-label',
    '/agency-commission',
    '/invoices',
  ],
};

export const moduleNames = {
  mainDashboards: 'mainDashboards',
  dashboardOverview: 'dashboardOverview',
  pagesDashboard: 'pagesDashboard',

  webStatsModule: 'webStats',

  webStatsOverview: 'webStatsOverview',
  trafficStructure: 'trafficStructure',
  trafficChannels: 'trafficChannels',
  trafficPages: 'trafficPages',
  trafficSessions: 'trafficSessions',
  trafficBounceRate: 'trafficBounceRate',

  visitors: 'visitors',
  visitorsOverview: 'visitorsOverview',
  visitorsMap: 'visitorsMap',
  visitorsCharts: 'visitorsCharts',
  visitorsLocation: 'visitorsLocation',
  visitorsCompanyDistribution: 'visitorsCompanyDistribution',

  pages: 'pages',
  pageVisits: 'pageVisits',
  landingPages: 'landingPages',
  conversionPages: 'conversionPages',
  referringSites: 'referringSites',

  competition: 'competition',

  devices: 'devices',

  utmCampaigns: 'utmCampaigns',
  utmOverview: 'utmOverview',
  utmVisitors: 'utmVisitors',
  utmMyCampaigns: 'utmMyCampaigns',
  utmCharts: 'utmCharts',
  utmBuilder: 'utmBuilder',

  websiteErrors: 'websiteErrors',

  visitorBehavior: 'visitorBehavior',
  eventTracking: 'eventTracking',
  autoEventTracking: 'autoEventTracking',
  manualEventTracking: 'manualEventTracking',
  alarmingBehaviorEventTracking: 'alarmingBehaviorEventTracking',
  eventTrackingGenerator: 'eventTrackingGenerator',

  sessionRecordings: 'sessionRecordings',

  heatmaps: 'heatmaps',

  funnels: 'funnels',

  feedback: 'feedback',
  polls: 'polls',
  surveys: 'surveys',

  privacyCenter: 'privacyCenter',
  privacyCenterOverview: 'privacyCenterOverview',
  privacyCenterDocuments: 'privacyCenterDocuments',

  tools: 'tools',
  toolsUrlShortener: 'toolsUrlShortener',
  toolsPrivacySticker: 'toolsPrivacySticker',

  websiteSettings: 'websiteSettings',
  generalSettings: 'generalSettings',
  subscription: 'subscription',
  moduleSettings: 'moduleSettings',
  eventTrackingSettings: 'eventTrackingSettings',
  sessionRecordingsSettings: 'sessionRecordingsSettings',
  trackingCode: 'trackingCode',
  manageContributors: 'manageContributors',
  segments: 'segments',
  consent: 'consent',
  specialPages: 'specialPages',
  import: 'import',
  export: 'export',
  roadmap: 'roadmap',

  // not visible in sidebar
  visitorsHistory: 'visitorsHistory',
  upgrade: 'upgrade',

  // TODO Add account settings modules?
};

export const modulePathsMap = {
  [moduleNames.mainDashboards]: '/dashboard',
  [moduleNames.dashboardOverview]: '/dashboard/overview',
  [moduleNames.pagesDashboard]: '/dashboard/pages',
  [moduleNames.webStatsModule]: '/statistics',
  [moduleNames.trafficStructure]: '/traffic/share',
  [moduleNames.trafficPages]: '/traffic/visits',
  [moduleNames.trafficSessions]: '/traffic/session',
  [moduleNames.trafficBounceRate]: '/traffic/bounceRate',

  [moduleNames.visitors]: '/visitors',
  [moduleNames.visitorsOverview]: '/visitors/overview',
  [moduleNames.visitorsMap]: '/visitors/map',
  [moduleNames.visitorsCharts]: '/visitors/charts',
  [moduleNames.visitorsLocation]: '/visitors/locations',
  [moduleNames.visitorsCompanyDistribution]: '/visitors/company-visits',

  [moduleNames.pages]: '/pages',
  [moduleNames.landingPages]: '/pages/landing',
  [moduleNames.conversionPages]: '/pages/conversions',
  [moduleNames.referringSites]: '/pages/referring',

  [moduleNames.competition]: '/competition',

  [moduleNames.devices]: '/devices',

  [moduleNames.utmCampaigns]: '/campaigns',
  [moduleNames.utmOverview]: '/campaigns/overview',
  [moduleNames.utmVisitors]: '/campaigns/latest',
  [moduleNames.utmMyCampaigns]: '/campaigns/my',
  [moduleNames.utmCharts]: '/campaigns/charts',
  [moduleNames.utmBuilder]: '/campaigns/utm-generator',

  [moduleNames.websiteErrors]: '/website-errors',

  [moduleNames.visitorBehavior]: '/behaviour',

  [moduleNames.eventTracking]: '/event-tracking',
  [moduleNames.manualEventTracking]: '/event-tracking/manual',
  [moduleNames.autoEventTracking]: '/event-tracking/auto',
  [moduleNames.alarmingBehaviorEventTracking]: '/event-tracking/alarming-behavior',
  [moduleNames.eventTrackingGenerator]: '/event-tracking/event-tag-generator',

  [moduleNames.sessionRecordings]: '/behaviour/recordings',

  [moduleNames.heatmaps]: '/behaviour/heatmaps',

  [moduleNames.funnels]: '/behaviour/funnels',

  [moduleNames.feedback]: '/feedback',
  [moduleNames.polls]: '/feedback/polls',
  [moduleNames.surveys]: '/feedback/surveys',

  [moduleNames.privacyCenter]: '/settings/privacy-center',
  [moduleNames.privacyCenterOverview]: '/settings/privacy-center/privacy-levels',
  [moduleNames.privacyCenterDocuments]: '/settings/privacy-center/legal-documents',

  [moduleNames.tools]: '/settings/privacy-center',

  [moduleNames.websiteSettings]: '/settings',
  [moduleNames.upgrade]: '/upgrade',
};

export const ALLOWED_MODULES_FOR_DASHBOARD_CONTRIBUTOR = [moduleNames.mainDashboards];

export const DISABLED_MODULES = [moduleNames.websiteErrors];
