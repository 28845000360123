import { CommentIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { fontWeights, Paragraph, paragraphSizes } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';

export const RecordingNoteIcon = ({ note }: { note: string }) => {
  const translate = useTranslate();

  return (
    <TooltipWrapper
      tooltipClassNames='!rounded-10.5 px-18.5 py-10.5'
      content={
        <>
          <p className='text-10.5 font-medium'>{translate('recordings.note.tooltip')}</p>
          <Paragraph weight={fontWeights.medium} size={paragraphSizes.tiny}>
            {note}
          </Paragraph>
        </>
      }
    >
      <div>
        <CommentIcon color='#FFBA42' />
      </div>
    </TooltipWrapper>
  );
};
