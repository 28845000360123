import * as ConversionsTypes from './ConversionsTypes';
import * as Device from './Device';
import * as DynamicPages from './DynamicPages';
import * as ExternalJs from './ExternalJs';
import * as Overview from './Overview';
import * as Page from './Page';
import * as SessionReplays from './SessionReplays';
import * as Settings from './Settings';
import * as Support from './Support';
import * as Visitors from './Visitors';
import * as Website from './Website';
import * as Account from './account';

/** @deprecated import from individual files*/
const API = {
  getWebsite: Website.getWebsite,
  agreement: Website.agreement,
  getAgreement: Website.getAgreement,
  signAgreement: Website.signAgreement,
  updateSubscription: Website.updateSubscription,
  getFeaturePermissions: Website.getFeaturePermissions,
  getAnnouncements: Website.getAnnouncements,
  getSettings: Settings.getSettings,
  getNotificationSettings: Settings.getNotificationSettings,
  getPrivacySettings: Website.getPrivacySettings,
  updateSettings: Settings.updateSettings,
  updateWebsiteFrontendFlags: Settings.updateWebsiteFrontendFlags,
  ignoreIp: Settings.ignoreIp,
  whitelistDomain: Settings.whitelistDomain,
  updateSettingsNotifications: Settings.updateSettings,
  sendFeedback: Support.sendFeedback,
  sendReview: Support.sendReview,

  getOverviewAggregatesPageVisits: Overview.getOverviewAggregatesPageVisits,

  getVisitor: Visitors.getSessionInfo,
  getLatestVisitorsList: Visitors.getLatestVisitorsList,
  getLatestVisitorsOss: Visitors.getLatestVisitorsOss,
  getLatestVisitorsBrowsers: Visitors.getLatestVisitorsBrowsers,
  getLatestVisitorsMap: Visitors.getLatestVisitorsMap,

  pageVisits: Page.visits,
  pageLanding: Page.landing,
  pageClickpaths: Page.clickpaths,
  pageConversions: Page.conversions,
  getPagesListSearchable: Page.getPagesListSearchable,
  pageReferers: Page.referers,
  /** @deprecated */
  referrerDetails: Page.referrerDetails,

  screensizeStats: Device.screensizeStats,
  browserStats: Device.browserStats,
  osStats: Device.osStats,
  devicesStats: Device.devicesStats,

  getVideoTrackingCode: SessionReplays.getVideoTrackingCode,
  checkSsrCode: SessionReplays.checkSsrCode,
  getRecordingsCount: SessionReplays.getRecordingsCount,
  createSsrSettings: SessionReplays.createSsrSettings,
  getSsrSettings: SessionReplays.getSsrSettings,
  updateSsrSettings: SessionReplays.updateSsrSettings,
  updateSessionExtras: SessionReplays.updateSessionExtras,

  getDynamicPages: DynamicPages.getDynamicPages,
  addDynamicPage: DynamicPages.addDynamicPage,
  editDynamicPage: DynamicPages.editDynamicPage,
  removeDynamicPage: DynamicPages.removeDynamicPage,

  getConversionTypes: ConversionsTypes.getConversionTypes,
  addConversionType: ConversionsTypes.addConversionType,
  editConversionType: ConversionsTypes.editConversionType,
  removeConversionType: ConversionsTypes.removeConversionType,

  getExternalDashboardScript: ExternalJs.getExternalDashboardScript,

  setUserFlag: Account.setUserFlag,
  getAccountInformation: Account.getAccountInformation,
};

export default API;
