import { useTranslate } from '@va/localization';
import { ComparisonFilter, MultiSelectFilter } from '@va/shared/feature-filters';
import { alarmingEventsMap, alarmingEventsTranslations } from '@va/types/events';
import { useMemo } from 'react';
import { countComparisonOperators, percentOfTotalComparisonOperators } from '../data/constants';

export const useAlarmingBehaviorEventsFilteringOptions = () => {
  const translate = useTranslate();
  const triggersOptions = Object.entries(alarmingEventsMap).map(([value]) => ({
    value: value,
    label: translate(alarmingEventsTranslations[value]),
  }));

  const options = useMemo(
    () => [
      new MultiSelectFilter({
        id: 'trigger',
        name: 'trigger',
        label: translate('all.eventTracking.alarmingBehaviorEvents.filters.alarmingBehaviorEvent'),
        inputProps: { useOptions: () => ({ data: triggersOptions }) },
      }),
      new ComparisonFilter({
        id: 'count',
        name: 'count',
        label: translate('all.eventTracking.alarmingBehaviorEvents.filters.numberOfDetections'),
        inputProps: { type: 'number' },
        operatorOptions: countComparisonOperators,
      }),
      new ComparisonFilter({
        id: 'percentOfTotal',
        name: 'percentOfTotal',
        label: translate('all.eventTracking.alarmingBehaviorEvents.filters.percentOfTotal'),
        inputProps: { type: 'number' },
        valueMapper(value) {
          return [value / 100];
        },
        operator: percentOfTotalComparisonOperators[0],
        operatorOptions: percentOfTotalComparisonOperators,
      }),
    ],
    [translate, triggersOptions],
  );

  return options;
};
