import { EventType, IncrementalSource, MouseInteractions } from '@visa/rrweb';
import { AlarmingEventTrigger, AlarmingEventType, EventTypeEnum } from '@va/types/recordings';
import { isAbesFeatureEnabled } from '@va/util/helpers';

export function onFullscreenChange(handler: () => void) {
  document.addEventListener('fullscreenchange', handler);
  document.addEventListener('webkitfullscreenchange', handler);
  document.addEventListener('mozfullscreenchange', handler);
  document.addEventListener('MSFullscreenChange', handler);

  return () => {
    document.removeEventListener('fullscreenchange', handler);
    document.removeEventListener('webkitfullscreenchange', handler);
    document.removeEventListener('mozfullscreenchange', handler);
    document.removeEventListener('MSFullscreenChange', handler);
  };
}

export function onWindowResize(handler: () => void) {
  window.addEventListener('resize', handler);
  return () => {
    window.removeEventListener('resize', handler);
  };
}

export function onMouseLeavePlayer(handler: () => void) {
  const player = document.getElementById('ssr-player');

  if (!player) return;

  player.addEventListener('mouseleave', handler);

  return () => {
    player.removeEventListener('mouseleave', handler);
  };
}

export function onMouseMovePlayer(handler: () => void) {
  const player = document.getElementById('ssr-player');

  if (!player) return;

  player.addEventListener('mousemove', handler);
  return () => {
    player.removeEventListener('mousemove', handler);
  };
}

export function isFullscreen() {
  // @ts-ignore
  return document.fullscreen || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement;
}

export function exitFullscreen() {
  if (document.exitFullscreen) {
    return document.exitFullscreen();
  } else if (document.mozExitFullscreen) {
    /* Firefox */
    return document.mozExitFullscreen();
  } else if (document.webkitExitFullscreen) {
    /* Chrome, Safari and Opera */
    return document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    /* IE/Edge */
    return document.msExitFullscreen();
  }
}

export function openFullscreen(el: Element) {
  if (el.requestFullscreen) {
    return el.requestFullscreen();
  } else if (el.mozRequestFullscreen) {
    /* Firefox */
    return el.mozRequestFullscreen();
  } else if (el.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    return el.webkitRequestFullscreen();
  } else if (el.msRequestFullscreen) {
    /* IE/Edge */
    return el.msRequestFullscreen();
  }
}

type SSREvent = {
  type: EventType;
  data: {
    href?: string;
    source?: number | string;
    type?: number;
  };
};

export function computeEventDetails(event: SSREvent) {
  switch (event.type) {
    case EventType.Meta:
      return {
        color: '#4A90E2',
        details: `Navigate to ${event.data.href}`,
        name: 'meta',
        extras: { url: event.data.href },
      };
    case EventType.FullSnapshot:
      return {
        color: '#F492F7',
        details: 'Full Snapshot',
        name: 'fullSnapshot',
      };
    case EventType.IncrementalSnapshot: {
      switch (event.data.source) {
        case IncrementalSource.MouseMove:
          return {
            color: '#3A505A',
            details: 'Mouse moved',
            name: 'mouseMove',
          };
        case IncrementalSource.Input:
          return {
            color: '#F6EC67',
            details: 'Input field change',
            name: 'input',
          };
        case IncrementalSource.Mutation:
          return { color: '#4A90E2', details: 'Mutation', name: 'mutation' };
        case IncrementalSource.Scroll:
          return { color: '#016D62', details: 'Scrolling', name: 'scroll' };
        case IncrementalSource.ViewportResize:
          return {
            color: '#73DEF6',
            details: 'Viewport resize',
            name: 'viewportResize',
          };
        case IncrementalSource.MouseInteraction: {
          switch (event.data.type) {
            case MouseInteractions.MouseUp:
              return { color: '#FDC96B', details: 'Mouse up', name: 'mouseUp' };
            case MouseInteractions.MouseDown:
              return {
                color: '#F2B44F',
                details: 'Mouse down',
                name: 'mouseDown',
              };
            case MouseInteractions.Click:
              return { color: '#FF5252', details: 'Click', name: 'click' };
            case MouseInteractions.ContextMenu:
              return {
                color: '#455773',
                details: 'Context menu',
                name: 'contextMenu',
              };
            case MouseInteractions.DblClick:
              return {
                color: '#C23853',
                details: 'Double click',
                name: 'dblClick',
              };
            case MouseInteractions.Focus:
              return { color: '#F27F4F', details: 'Focus', name: 'focus' };
            case MouseInteractions.Blur:
              return { color: '#49C5B6', details: 'Blur', name: 'blur' };
            case MouseInteractions.TouchStart:
              return {
                color: '#F492F7',
                details: 'Touch start',
                name: 'touchStart',
              };
            case MouseInteractions.TouchMove_Departed:
              return {
                color: '#8F7CED',
                details: 'Touch move',
                name: 'touchMove',
              };
            case MouseInteractions.TouchEnd:
              return {
                color: '#8F7CED',
                details: 'Touch end',
                name: 'touchEnd',
              };
            default:
              return {
                color: '#FDC96B',
                details: `${MouseInteractions[event.data.type as unknown as keyof typeof MouseInteractions]}`,
              };
          }
        }
        default:
          return {
            color: '#55BFA0',
            details: `${IncrementalSource[event.data.source as keyof typeof IncrementalSource]}`,
          };
      }
    }
    case EventType.Custom: {
      switch (event.data.source) {
        case AlarmingEventTrigger.rageClicks: {
          return {
            color: '#FF0000',
            details: event.data.type,
            name:
              event.data.type === AlarmingEventType.ABE_START
                ? EventTypeEnum.rageClickStart
                : EventTypeEnum.rageClickEnd,
          };
        }
        case AlarmingEventTrigger.deadClicks: {
          return {
            color: '#FF0000',
            details: event.data.type,
            name: EventTypeEnum.deadClick,
          };
        }
        case AlarmingEventTrigger.scrolls: {
          return {
            color: '#FF0000',
            details: event.data.type,
            name: event.data.type === AlarmingEventType.ABE_START ? EventTypeEnum.scrollStart : EventTypeEnum.scrollEnd,
          };
        }
        case AlarmingEventTrigger.mouseMoves: {
          return {
            color: '#FF0000',
            details: event.data.type,
            name:
              event.data.type === AlarmingEventType.ABE_START
                ? EventTypeEnum.mouseMovesStart
                : EventTypeEnum.mouseMovesEnd,
          };
        }
        case AlarmingEventTrigger.uTurns: {
          return {
            color: '#FF0000',
            details: event.data.type,
            name: EventTypeEnum.uTurn,
          };
        }
        case AlarmingEventTrigger.refreshes: {
          return {
            color: '#FF0000',
            details: event.data.type,
            name: EventTypeEnum.refresh,
          };
        }

        default: {
          return { color: '#FDC96B', details: event.data.source };
        }
      }
    }

    default:
      return { color: '#FDC96B', details: `${EventType[event.type]}` };
  }
}

export const isEventNeeded = (event: SSREvent) => {
  switch (event.type) {
    case EventType.Meta:
      return true;
    case EventType.IncrementalSnapshot: {
      switch (event.data.source) {
        case IncrementalSource.Input:
        case IncrementalSource.Scroll:
        case IncrementalSource.ViewportResize:
          return true;
        case IncrementalSource.MouseInteraction: {
          switch (event.data.type) {
            case MouseInteractions.Click:
            case MouseInteractions.MouseDown:
            case MouseInteractions.MouseUp:
            case MouseInteractions.DblClick:
            case MouseInteractions.Focus:
            case MouseInteractions.TouchStart:
            case MouseInteractions.TouchEnd:
              return true;
            default:
              return false;
          }
        }
        default:
          return false;
      }
    }
    case EventType.Custom: {
      switch (event.data.source) {
        case AlarmingEventTrigger.rageClicks:
        case AlarmingEventTrigger.deadClicks:
        case AlarmingEventTrigger.scrolls:
        case AlarmingEventTrigger.mouseMoves:
        case AlarmingEventTrigger.refreshes:
        case AlarmingEventTrigger.uTurns:
          return isAbesFeatureEnabled();
        default:
          return false;
      }
    }
    default:
      return false;
  }
};

export const scrollToActiveElement = (element: Element, wrapperRef: HTMLElement) => {
  const elementOffsetTop = wrapperRef.scrollTop + element.getBoundingClientRect().top;
  // Half of wrapper height to position element in center
  wrapperRef.scrollTop = elementOffsetTop - wrapperRef.offsetHeight / 2;
};
