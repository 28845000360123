import { ClickIcon, MouseScrollIcon } from '@va/icons';
import { alarmingEventsTranslations, regularEventsMap, regularEventsTranslations } from '@va/types/events';

export const iconMap: Record<string, JSX.Element> = {
  [regularEventsMap.clickInteractions]: <ClickIcon color='#AC53D4' />,
  [regularEventsMap.moveInteractions]: <MouseScrollIcon color='#AC53D4' />,
  [regularEventsMap.scrollInteractions]: <MouseScrollIcon color='#AC53D4' />,
};

export const actionsTranslations = {
  ...alarmingEventsTranslations,
  ...regularEventsTranslations,
};
