import { Replayer } from '@visa/rrweb';
import '@visa/rrweb/dist/rrweb.min.css';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Controls from '../Controls/Controls';
import {
  computeEventDetails,
  exitFullscreen,
  isEventNeeded,
  isFullscreen,
  onFullscreenChange,
  onMouseLeavePlayer,
  onMouseMovePlayer,
  onWindowResize,
  openFullscreen,
} from '../helpers';
import './styles.css';

class Player extends Component {
  constructor(props) {
    super(props);

    this.state = {
      progressBarEvents: [],
      idleState: false,
      offsetX: 0,
      offsetY: 0,
      isFullscreen: false,
    };

    this.playerRef = React.createRef();
    this.frameRef = React.createRef();
    this.controlsWrapperRef = React.createRef();
    this.controlsRef = React.createRef();
    this.ssrInactiveTimeout = undefined;
  }

  componentDidMount() {
    const { events } = this.props;

    if (!events) {
      alert('Events not initialized! Please make sure to render the player only when the events request is finished!');
      return;
    }

    this.initializeReplayer();

    this.mouseLeavePlayerListener = onMouseLeavePlayer(() => {
      if (!this.controlsWrapperRef.current) return;

      this.controlsWrapperRef.current.classList.add('ssr-inactive');
      this.setState({ idleState: true });
    });

    this.moveMouseListener = onMouseMovePlayer((e) => {
      if (!this.controlsWrapperRef.current) return;
      this.setState({ offsetX: e.offsetX, offsetY: e.offsetY });

      if (this.state.idleState) {
        this.controlsWrapperRef.current.classList.remove('ssr-inactive');
        this.setState({ idleState: false });
      }
    });

    this.windowResizeListener = onWindowResize(() => {
      if (!this.state.replayer) return;

      this.updateScale(this.state.replayer.wrapper, {
        width: this.state.replayer.iframe.offsetWidth,
        height: this.state.replayer.iframe.offsetHeight,
      });
    });
  }

  resetSsrInactiveTimeout() {
    if (!this.ssrInactiveTimeout) return;
    clearTimeout(this.ssrInactiveTimeout);
  }

  mapEventForProgressBar = (event, recordingBaselineTime, progressBarDuration, page) => {
    const { hideEventInProgressBar } = this.props;
    if (!isEventNeeded(event) || hideEventInProgressBar?.(event)) return;

    const { getEventText } = this.props;
    const delay = event.timestamp - recordingBaselineTime + progressBarDuration;
    const eventDetails = computeEventDetails(event);

    return {
      id: event.id,
      type: event.type,
      source: event.data?.source,
      delay,
      ...eventDetails,
      details: getEventText(eventDetails.name, eventDetails.extras),
      recordingId: event?.recordingId,
      page,
    };
  };

  /**
   * Removes time gaps between recordings
   * Shifts recordings' timestamps so that t_[n]_start = t_[n-1]_end + 1, while keeping the length of the recording unmodified
   * Where [n] is the recording number
   *
   * ex: Recording 1 starts at 12:00 and ends at 12:10
   * Recording 2 starts at 12:20 and ends at 12:30
   * After shifting, Recording 1 will remain unaltered
   * Recording 2 will start at 12:11 and end at 12:21
   * @returns all events in all recordings
   */
  processEvents = () => {
    const { events, recordings } = this.props;

    let timeOffset = 0,
      progressBarDuration = 0,
      processedEvents = [],
      progressBarEvents = [];

    const sortedRecordings = recordings.sort((a, b) => a.createdAt - b.createdAt);

    sortedRecordings.forEach((recording, recordingIndex) => {
      if (!events[recording.id]) return;
      const recordingEvents = events[recording.id].sort((a, b) => {
        return a.timestamp - b.timestamp;
      });

      const recordingBaselineTime = recordingEvents[0].timestamp;

      const processedEventsForRecording = recordingEvents.map((event) => {
        const progressBarEvent = this.mapEventForProgressBar(
          event,
          recordingBaselineTime,
          progressBarDuration,
          recording.page,
        );

        if (progressBarEvent) {
          progressBarEvents.push(progressBarEvent);
        }

        let returnedEvent;
        if (recordingIndex === 0) {
          returnedEvent = event;
        } else {
          returnedEvent = {
            ...event,
            timestamp: event.timestamp - recordingBaselineTime + timeOffset,
          };
        }

        return returnedEvent;
      });

      processedEvents = processedEvents.concat(processedEventsForRecording);
      // one extra millisecond for gap
      progressBarDuration += recording.duration + 1;

      if (recordingIndex === 0) {
        // set the time offset to the last element of the first recording + 1 millisecond
        timeOffset += recordingEvents[recordingEvents.length - 1].timestamp + 1;
        return;
      }

      // adds a gap of 1 millisecond after each recording
      timeOffset += recording.duration + 1;
    });

    progressBarEvents = progressBarEvents.sort((a, b) => a.timestamp - b.timestamp);

    this.setState({ progressBarEvents });
    this.props.onEventsParsed(progressBarEvents);

    return processedEvents;
  };

  initializeReplayer = () => {
    const replayerEvents = this.processEvents();

    const replayer = new Replayer(replayerEvents, {
      speed: 1,
      root: this.frameRef.current,
      skipInactive: false,
      showWarning: true,
      showDebug: true,
      triggerFocus: false,
    });

    this.resizeListener = replayer.on('resize', (dimension) => this.updateScale(replayer.wrapper, dimension));
    this.fullScreenListener = onFullscreenChange(() => {
      if (isFullscreen()) {
        setTimeout(() => {
          const { width, height } = this.state;
          // store the original dimension which does not need to be reactive
          this._width = width;
          this._height = height;
          const dimension = {
            width: document.body.offsetWidth,
            height: document.body.offsetHeight - this.controlsWrapperRef.current.clientHeight,
          };
          this.setState(dimension);
          this.updateScale(replayer.wrapper, {
            width: replayer.iframe.offsetWidth,
            height: replayer.iframe.offsetHeight,
          });
        }, 0);
      } else {
        this.setState({
          width: this._width,
          height: this._height,
        });
        this.updateScale(replayer.wrapper, {
          width: replayer.iframe.offsetWidth,
          height: replayer.iframe.offsetHeight,
        });
      }
    });

    this.setState({ replayer });
  };

  componentWillUnmount() {
    this.resetSsrInactiveTimeout();

    if (this.fullScreenListener) {
      this.fullScreenListener();
    }

    if (this.windowResizeListener) {
      this.windowResizeListener();
    }

    if (this.mouseLeavePlayerListener) {
      this.mouseLeavePlayerListener();
    }

    if (this.moveMouseListener) {
      this.moveMouseListener();
    }
  }

  updateScale(el, frameDimension) {
    if (!this.frameRef.current) return;

    const widthScale = this.frameRef.current.clientWidth / frameDimension.width;
    const heightScale = this.frameRef.current.clientHeight / frameDimension.height;
    el.style.transform = `scale(${Math.min(widthScale, heightScale, 1)})` + 'translate(-50%, -50%)';

    this.setState({
      widthScale,
      heightScale,
    });
  }

  addEventListener = (event, handler) => {
    this.state.replayer.on(event, handler);
  };

  onFullscreen = () => {
    if (this.props.onFullScreen) {
      this.props.onFullScreen();
      return;
    }

    if (this.playerRef && this.playerRef.current) {
      if (isFullscreen()) {
        exitFullscreen();
        this.setState({ isFullscreen: false });
      } else {
        openFullscreen(this.playerRef.current);
        this.setState({ isFullscreen: true });
      }
    }
  };

  playFromEvent(event) {
    if (!this.controlsRef.current) return;
    this.controlsRef.current.playFromTimeOffset(event.delay);
  }

  render() {
    const { recordings } = this.props;

    let frameHeight = 0;
    if (this.playerRef.current && this.controlsWrapperRef.current) {
      const frameWidth = this.playerRef.current.clientWidth;
      frameHeight = (frameWidth * 9) / 16;
    }

    const frameStyle = {
      width: '100%',
      height: frameHeight,
      maxHeight:
        this.controlsWrapperRef && this.controlsWrapperRef.current
          ? `calc(100% - ${this.controlsWrapperRef.current.clientHeight}px)`
          : '100%',
    };

    const playerStyle = {
      width: '100%',
      display: 'flex',
      alignItems: 'space-between',
    };

    const controlsWrapperStyle = {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
    };

    return (
      <div id={'ssr-player'} className={'ssr-player'} style={playerStyle} ref={this.playerRef}>
        <div
          style={{
            width: this.props.showEventsLog ? '70%' : '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className={'ssr-player-frame'} ref={this.frameRef} style={frameStyle} />
          <div id={'controls-wrapper'} ref={this.controlsWrapperRef} style={controlsWrapperStyle}>
            {this.state.replayer && (
              <Controls
                ref={this.controlsRef}
                replayer={this.state.replayer}
                autoPlay={this.props.autoPlay}
                autoPlayStart={this.props.autoPlayStart}
                onFullscreen={this.onFullscreen}
                playButton={this.props.playButton}
                skipInactiveSwitch={this.props.skipInactiveSwitch}
                speedItem={this.props.speedItem}
                speedValues={this.props.speedValues}
                toggleFullscreenButton={this.props.toggleFullscreenButton}
                showEventsLog={this.props.showEventsLog}
                controls={this.props.controls}
                recordings={recordings}
                progressBarEvents={this.state.progressBarEvents}
                onCurrentEventChange={this.props.onCurrentEventChange}
                isPlayerFullscreen={this.state.isFullscreen}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

Player.defaultProps = {
  autoPlay: true,
  showEventsLog: true,
};

Player.propTypes = {
  events: PropTypes.object.isRequired,
  autoPlay: PropTypes.bool,
  autoPlayStart: PropTypes.number,
  playButton: PropTypes.func,
  skipInactiveSwitch: PropTypes.func,
  toggleFullscreenButton: PropTypes.func,
  speedValues: PropTypes.arrayOf(PropTypes.number),
  speedItem: PropTypes.func,
  showEventsLog: PropTypes.bool.isRequired,
  controls: PropTypes.func,
  recordings: PropTypes.array.isRequired,
  onEventsParsed: PropTypes.func.isRequired,
  onCurrentEventChange: PropTypes.func.isRequired,
  getEventText: PropTypes.func.isRequired,
  onFullScreen: PropTypes.func,
  hideEventInProgressBar: PropTypes.func,
};

export default Player;
