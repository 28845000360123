import { TEST_IDS } from '@va/constants';
import { FilterIcon, LayersIcon } from '@va/icons';
import { useLocalizationContext } from '@va/localization';
import {
  Button,
  Favicon,
  fontWeights,
  IconWithBackground,
  ListCardItemWithIcon,
  Paragraph,
  paragraphSizes,
  ParagraphWithTooltip,
} from '@va/ui/design-system';
import { addNumberSeparator, getWellFormedUrl } from '@va/util/helpers';
import classNames from 'classnames';
import { sumBy } from 'lodash';
import { FC, useMemo } from 'react';
import { ABEReferrer } from '../../types/types';

type ReferrersCellProps = {
  referrers: ABEReferrer[];
};

export const ReferrersCell: FC<ReferrersCellProps> = ({ referrers }) => {
  const { locale } = useLocalizationContext();
  const total = sumBy(referrers, 'referredSessions');

  return (
    <Button
      data-testid={TEST_IDS.generic.links.visitedPagesHistory}
      className='justify-center'
      color='secondary'
      text={addNumberSeparator(total, locale)}
      icon={(_, color) => <LayersIcon color={color} />}
      tooltip={<ReferrersCellTooltip referrers={referrers} />}
      tooltipProps={{ useDefaultStyle: false }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    />
  );
};

const ReferrersCellTooltip: FC<{ referrers: ABEReferrer[] }> = ({ referrers }) => {
  const { locale, translate } = useLocalizationContext();

  const sortedReferrers = useMemo(() => referrers.sort((a, b) => b.referredSessions - a.referredSessions), [referrers]);

  const onUrlClick = (e: React.MouseEvent<Element>, url: string) => {
    window.open(url, '_blank');
    e.stopPropagation();
  };

  return (
    <div
      className='flex flex-col p-24px gap-12px bg-black-lighter-84 rounded-24 text-white max-w-420px'
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div>
        <Paragraph size={paragraphSizes.large} weight={fontWeights.medium}>
          {translate('all.abes.referrersTooltip.title')}
        </Paragraph>
        <div className={'flex items-center gap-9px'}>
          <FilterIcon color={'#FFFFFF'} />
          <Paragraph size={paragraphSizes.normal} weight={fontWeights.medium}>
            {translate('all.abes.referrersTooltip.subtitle')}
          </Paragraph>
        </div>
      </div>
      <div className={'grid gap-12px items-center'} style={{ gridTemplateColumns: 'auto min-content' }}>
        <Paragraph size={paragraphSizes.tiny}>{translate('all.abes.referrersTooltip.urlsColumn')}</Paragraph>
        <Paragraph size={paragraphSizes.tiny} className={'text-right text-nowrap'}>
          {translate('all.abes.referrersTooltip.sessionsCountColumn')}
        </Paragraph>
        {sortedReferrers.length === 0 && (
          <Paragraph className={'col-span-2 text-center'}>{translate('all.abes.referrersTooltip.noData')}</Paragraph>
        )}
      </div>
      <div
        className={'grid grid-cols-2 gap-12px w-full items-center max-h-40 overflow-y-auto scrollbar scrollbar-thumb'}
      >
        {sortedReferrers.map((r) => {
          const wellFormedUrl = getWellFormedUrl(r.referrer);
          return (
            <div key={r.referrer} className={'contents'}>
              <ListCardItemWithIcon
                onClick={(e) => onUrlClick(e, wellFormedUrl)}
                icon={
                  <IconWithBackground
                    className={'cursor-pointer'}
                    type={'circle'}
                    style={{
                      backgroundColor: 'rgba(255, 255, 255, 0.17)',
                    }}
                    icon={() => <Favicon baseUrl={wellFormedUrl} />}
                  />
                }
                bottomNode={
                  <ParagraphWithTooltip
                    weight={fontWeights.medium}
                    className={classNames('cursor-pointer text-left', {})}
                  >
                    <a href={wellFormedUrl} target={'_blank'} rel='noreferrer'>
                      {wellFormedUrl}
                    </a>
                  </ParagraphWithTooltip>
                }
                topText={''}
              />

              <Paragraph className={'text-right'}>{addNumberSeparator(r.referredSessions, locale)}</Paragraph>
            </div>
          );
        })}
      </div>
    </div>
  );
};
