import { useCurrentPeriodFilter } from '@va/dashboard/util-hooks';
import { post } from '@va/http-client';
import { useFiltersContext } from '@va/shared/feature-filters';
import { RawTimeseriesResponse } from '@va/types/time-series';
import { buildApiConditions } from '@va/util/helpers';
import { SWRResponse, useFetchData } from '@va/util/hooks';
import { useCallback, useMemo } from 'react';
import { DEFAULT_GROUP } from '../data/constants';
import { DEFAULT_ORDER } from '../data/order';
import { mapAlarmingBehaviorResponse } from '../helpers/apiHelper';
import {
  AlarmingBehaviorEventsFetchParams,
  AlarmingBehaviorEventsGraphFetchParams,
  AlarmingBehaviorResponse,
  UseAlarmingBehaviorEventsParams,
} from '../types/types';

export const useAlarmingBehaviorEvents = ({
  pagination,
  order = DEFAULT_ORDER,
  group = DEFAULT_GROUP,
  initialFilters,
}: UseAlarmingBehaviorEventsParams): SWRResponse<AlarmingBehaviorResponse, Error> => {
  const { appliedFilters } = useFiltersContext();
  const { from, until, websiteId } = useCurrentPeriodFilter();

  const where = useMemo(
    () => ({
      and: initialFilters
        ? buildApiConditions([...appliedFilters, ...initialFilters])
        : buildApiConditions(appliedFilters),
    }),
    [appliedFilters, initialFilters],
  );
  const url = useMemo(() => `/v2/websites/${websiteId}/alarming-events`, [websiteId]);
  const sorting = useMemo(() => {
    if (!order?.at(0)) return undefined;
    return `${order?.at(0)?.member}.${order?.at(0)?.direction}`;
  }, [order]);

  const queryData = useMemo(
    () => ({
      from,
      until,
      group,
      page: pagination.page,
      pageSize: pagination.pageSize,
      order: sorting,
    }),
    [from, until, group, pagination.page, pagination.pageSize, sorting],
  );
  const body = useMemo(
    () => ({
      where: where,
    }),
    [where],
  );

  const fetcher = useCallback(async (params: AlarmingBehaviorEventsFetchParams) => {
    const { url, queryData, body } = params;
    const eventsResponse = await post(url, queryData, body);
    return eventsResponse;
  }, []);

  return useFetchData<AlarmingBehaviorResponse, Error>(
    [{ url, queryData, body }],
    {
      revalidateOnFocus: false,
    },
    mapAlarmingBehaviorResponse,
    fetcher,
  );
};

export async function fetchAlarmingBehaviorEventsGraph(params: AlarmingBehaviorEventsGraphFetchParams) {
  const { websiteId, query, body } = params;
  const graphResponse = await post<RawTimeseriesResponse>(
    `/v2/websites/${websiteId}/alarming-events/graph`,
    query,
    body,
  );
  return graphResponse;
}
