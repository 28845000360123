import { TEST_IDS } from '@va/constants';
import { ClickIcon } from '@va/icons';
import { AlarmingEvents, Events } from '@va/types/events';
import { Button, VisitorActionsTooltip } from '@va/ui/design-system';
import { useIsHovering } from '@va/util/hooks';
import classNames from 'classnames';
import { useRef } from 'react';
import { sumObjectValues } from '../helpers/visitorActions';

type VisitorActionsCellProps = {
  alarmingEvents: AlarmingEvents;
  regularEvents: Events;
};

export function VisitorActionsCell({ alarmingEvents, regularEvents }: VisitorActionsCellProps) {
  const visitorActionsBtnRef = useRef<HTMLButtonElement>(null);
  const isHovering = useIsHovering(visitorActionsBtnRef);
  const alarmingEventsTotal = sumObjectValues(alarmingEvents);
  const eventsTotal = sumObjectValues(regularEvents);
  const total = alarmingEventsTotal + eventsTotal;

  return (
    <div className='w-full flex items-center justify-center px-3'>
      <Button
        text={total}
        icon={(className, color) => <ClickIcon className={className} color={color} />}
        className={classNames('min-w-fit', {
          'text-primary ': !isHovering,
        })}
        onClick={(event) => event.stopPropagation()}
        ref={visitorActionsBtnRef}
        color={isHovering ? 'primary' : 'quinary'}
        tooltip={
          total > 0 && (
            <VisitorActionsTooltip
              alarmingEvents={alarmingEvents}
              alarmingEventsTotal={alarmingEventsTotal}
              regularEvents={regularEvents}
              regularEventsTotal={eventsTotal}
            />
          )
        }
        tooltipProps={{
          interactive: true,
          tooltipClassNames: '!rounded-3xl md:min-w-[520px]',
        }}
        data-testid={TEST_IDS.generic.buttons.seeMore}
      />
    </div>
  );
}
