import { createAction } from '@reduxjs/toolkit';
import { makeAction } from '@va/store';
import { Filter } from '@va/types/filters';

export const SET_ACTIVE_TAB = 'UI_SET_ACTIVE_TAB';
export const SET_GLOBAL_FILTER = 'UI_SET_GLOBAL_FILTER';
export const SET_TRAFFIC_FILTER = 'UI_SET_TRAFFIC_FILTER';
export const SET_STEP_FILTER = 'UI_SET_STEP_FILTER';
export const SET_RECORDINGS_OVERVIEW_INITIAL_FILTERS = 'UI_SET_RECORDINGS_OVERVIEW_INITIAL_FILTER';

export const SET_TIME_FILTER = 'SET_TIME_FILTER';

export const SET_TAB_PROPERTY = 'UI_SET_TAB_PROPERTY';
export const SET_NOTIFICATION_SYSTEM = 'UI_SET_NOTIFICATION_SYSTEM';
export const ADD_NOTIFICATION = 'UI_ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'UI_REMOVE_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = 'UI_CLEAR_NOTIFICATIONS';
export const RESET_CLEAR_NOTIFICATIONS = 'UI_RESET_CLEAR_NOTIFICATIONS';
export const SET_LOCALE = 'UI_SET_LOCALE';
export const SHOULD_REFRESH_DATEPICKER = 'UI_SHOULD_REFRESH_DATEPICKER';

export const REQUEST_TRANSLATIONS = 'UI_REQUEST_TRANSLATIONS';
export const REQUEST_TRANSLATIONS_SUCCEEDED = 'UI_REQUEST_TRANSLATIONS_SUCCEEDED';
export const REQUEST_TRANSLATIONS_FAILED = 'UI_REQUEST_TRANSLATIONS_FAILED';

export const ADD_TOOLTIP = 'UI_ADD_TOOLTIP';
export const ADD_TOASTER_TOOLTIP = 'UI_ADD_TOASTER_TOOLTIP';
export const UPDATE_TOOLTIP = 'UI_UPDATE_TOOLTIP';
export const UPDATE_TOOLTIPS = 'UI_UPDATE_TOOLTIPS';
export const UPDATE_ALL_TOOLTIPS = 'UI_UPDATE_ALL_TOOLTIPS';
export const REMOVE_TOOLTIP = 'UI_REMOVE_TOOLTIP';

export const TOGGLE_NAVIGATION_BAR_EXTENSION = 'UI_TOGGLE_NAVIGATION_BAR_EXTENSION';
export const TOGGLE_DATEPICKER_VISIBILITY = 'UI_TOGGLE_DATEPICKER_VISIBILITY';
export const TOGGLE_BAR_CHART_SHOWN_DATA = 'UI_TOGGLE_BAR_CHART_SHOWN_DATA';

export const SET_NAVIGATION_TITLE = 'UI_SET_NAVIGATION_TITLE';

export const SET_WINDOW_WIDTH = 'UI_SET_WINDOW_WIDTH';

export const SET_DEMOGRAPHIC_COUNTRY = 'UI_SET_DEMOGRAPHIC_COUNTRY';

export const OPEN_MODAL = 'UI_OPEN_MODAL';
export const CLOSE_MODAL = 'UI_CLOSE_MODAL';
export const OPEN_CHECK_LOGIN_MODAL = 'UI_OPEN_CHECK_LOGIN_MODAL';
export const SET_MODAL_MOUNTED = 'UI_SET_MODAL_MOUNTED';
export const SET_PASSWORD_WRONG_MESSAGE_VISIBILITY = 'UI_SET_PASSWORD_WRONG_MESSAGE_VISIBILITY';
export const SHOW_BANNER = 'UI_SHOW_BANNER';
export const HIDE_BANNER = 'UI_HIDE_BANNER';

export const UPGRADE_REQUEST = 'UI_UPGRADE_REQUEST';
export const RESET_UPGRADE_REQUEST = 'UI_RESET_UPGRADE_REQUEST';

export const SET_CARD_VIEW = 'UI_SET_CARD_VIEW';

export const SET_CONSENT_IN_SESSION = 'UI_SET_CONSENT_IN_SESSION';

export const TOGGLE_CHECK_HEATMAP_SESSION = 'UI_TOGGLE_CHECK_HEATMAP_SESSION';

export const SET_SESSION_EXTRAS = 'UI_SET_SESSION_EXTRAS';

export const SET_ANNOUNCEMENT_DATA = 'UI_SET_ANNOUNCEMENT_DATA';
export const MARK_ANNOUNCEMENT_VIEWED_IN_SESSION = 'UI_MARK_ANNOUNCEMENT_VIEWED_IN_SESSION';
export const SET_DEVICE_PAGE_BAR_CHART_ID = 'UI_SET_DEVICE_PAGE_BAR_CHART_ID';

export const RESET_PANELS_PAGINATION = 'UI_RESET_PANELS_PAGINATION';

export const SET_VISITORS_CHART_WITH_PREVIOUS = 'UI_SET_VISITORS_CHART_WITH_PREVIOUS';
export const TOGGLE_STEP_FILTER_WITH_PREVIOUS = 'UI_TOGGLE_STEP_FILTER_WITH_PREVIOUS';

export const SET_COMPETITION_SORTING_OPTION = 'SET_COMPETITION_SORTING_OPTION';

export const SET_IS_WELCOME_PAGE = 'SET_IS_WELCOME_PAGE';

export const setNavigationTitle = makeAction(SET_NAVIGATION_TITLE, 'title');

export const setActiveTab = makeAction(SET_ACTIVE_TAB, 'tab');
export const setGlobalFilter = makeAction(SET_GLOBAL_FILTER, 'filter');
export const setTrafficFilter = makeAction(SET_TRAFFIC_FILTER, 'filter');
export const setStepFilter = makeAction(SET_STEP_FILTER, 'filter');
export const setRecordingsOverviewInitialFilters = makeAction<[Filter[]]>(
  SET_RECORDINGS_OVERVIEW_INITIAL_FILTERS,
  'filter',
);

export const setTabProperty = makeAction(SET_TAB_PROPERTY, 'tab', 'propertyName', 'propertyValue');
export const addNotification = makeAction(ADD_NOTIFICATION, 'notification');
export const removeNotification = makeAction(REMOVE_NOTIFICATION);
export const clearNotifications = makeAction(CLEAR_NOTIFICATIONS);
export const resetClearNotifications = makeAction(RESET_CLEAR_NOTIFICATIONS);
export const setLocale = makeAction(SET_LOCALE, 'locale');
export const setShouldRefreshDatePicker = makeAction(SHOULD_REFRESH_DATEPICKER);

export const ADD_NOTIFICATION_V2 = 'ADD_NOTIFICATION_V2';
export const REMOVE_NOTIFICATION_V2 = 'REMOVE_NOTIFICATION_V2';
export const addNotificationV2 = makeAction(ADD_NOTIFICATION_V2, 'payload');
export const removeNotificationV2 = makeAction(REMOVE_NOTIFICATION_V2);

/**
 * @deprecated
 */
export const requestTranslations = makeAction(REQUEST_TRANSLATIONS, 'locale', 'isFirstLoad');
export const requestTranslationsSucceeded = makeAction(REQUEST_TRANSLATIONS_SUCCEEDED, 'data', 'locale');
export const requestTranslationsFailed = makeAction(REQUEST_TRANSLATIONS_FAILED, 'error', 'locale');

export const addTooltip = makeAction(ADD_TOOLTIP, 'id', 'render', 'options');
export const addToasterTooltip = makeAction(ADD_TOASTER_TOOLTIP, 'id', 'expirationTime', 'place', 'render', 'options');
export const updateTooltip = makeAction(UPDATE_TOOLTIP, 'id', 'options');
export const updateTooltips = makeAction(UPDATE_TOOLTIPS, 'tooltips');
export const updateAllTooltips = makeAction(UPDATE_ALL_TOOLTIPS, 'options');
export const removeTooltip = makeAction(REMOVE_TOOLTIP, 'id');

export const toggleDatePickerVisibility = makeAction(TOGGLE_DATEPICKER_VISIBILITY);
export const toggleBarChartShownData = makeAction(TOGGLE_BAR_CHART_SHOWN_DATA, 'chartId');

export const setWindowWidth = makeAction(SET_WINDOW_WIDTH, 'width');

export const setDemographicCountry = makeAction(SET_DEMOGRAPHIC_COUNTRY, 'country');

export const openModal = makeAction(OPEN_MODAL, 'modalName', 'extraProps');
export const closeModal = makeAction(CLOSE_MODAL, 'modalName');
export const openCheckLoginModal = makeAction(OPEN_CHECK_LOGIN_MODAL, 'pendingModalName', 'colorTheme');
export const setModalMounted = makeAction(SET_MODAL_MOUNTED);
export const setPasswordWrongMessageVisibility = makeAction(SET_PASSWORD_WRONG_MESSAGE_VISIBILITY, 'value');
export const showBanner = makeAction(SHOW_BANNER, 'bannerName');
export const hideBanner = makeAction(HIDE_BANNER, 'bannerName');

export const upgradeRequest = makeAction(UPGRADE_REQUEST, 'id');
export const resetUpgradeRequest = makeAction(RESET_UPGRADE_REQUEST);

export const setCardView = makeAction(SET_CARD_VIEW, 'cardName', 'view', 'previousView');

export const setConsentInSession = makeAction(SET_CONSENT_IN_SESSION, 'consentFlag', 'value');

export const setSessionExtras = makeAction(SET_SESSION_EXTRAS, 'data', 'sessionId', 'sessionIds');

export const setAnnouncementData = makeAction(SET_ANNOUNCEMENT_DATA, 'value');
export const markAnnouncementViewedInSession = makeAction(MARK_ANNOUNCEMENT_VIEWED_IN_SESSION);
export const setDevicePageBarChartId = makeAction(SET_DEVICE_PAGE_BAR_CHART_ID, 'value');

export const resetPanelsPagination = makeAction(RESET_PANELS_PAGINATION);

export const toggleStepFilterWithPrevious = makeAction(TOGGLE_STEP_FILTER_WITH_PREVIOUS);

export const setCompetitionSortingOption = makeAction(SET_COMPETITION_SORTING_OPTION, 'category', 'id');
export const setIsWelcomePage = makeAction(SET_IS_WELCOME_PAGE, 'value');

export const setProviderName = createAction<string>('SET_PROVIDER_NAME');
export const setSupportCenterDomain = createAction<string>('SET_SUPPORT_CENTER_DOMAIN');
export const setChatwootToken = createAction<string>('SET_CHATWOOT_TOKEN');
export const setCustomizationOptions = createAction<{
  providerName: string;
  supportCenterDomain: string;
  chatwootToken: string;
}>('SET_CUSTOMIZATION_OPTIONS');
