import { NegativeTagIcon } from '@va/icons';
import { useLocalizationContext } from '@va/localization';
import { AlarmingEvents, Events } from '@va/types/events';
import { fontWeights, IconWithBackground, Paragraph, paragraphSizes, ParagraphWithTooltip } from '@va/ui/design-system';
import { CloseButton, useTooltipContext } from '@va/ui/tooltips';
import { addNumberSeparator } from '@va/util/helpers';
import { useWindowDimensions } from '@va/util/hooks';
import { VisitorActionListItem } from './VisitorActionListItem';
import { actionsTranslations, iconMap } from './constants/visitorActions';

type VisitorActionsTooltipProps = {
  alarmingEvents: AlarmingEvents;
  alarmingEventsTotal: number;
  regularEvents: Events;
  regularEventsTotal: number;
};

export function VisitorActionsTooltip({
  alarmingEvents,
  alarmingEventsTotal,
  regularEvents,
  regularEventsTotal,
}: VisitorActionsTooltipProps) {
  const { locale, translate } = useLocalizationContext();
  const { height: windowHeight } = useWindowDimensions();
  const eventEntries = Object.entries({ ...alarmingEvents, ...regularEvents }).filter(([, value]) => value > 0);
  const { context } = useTooltipContext();
  return (
    <div
      className='rounded-3xl flex flex-col gap-2 overflow-hidden'
      style={{ maxHeight: windowHeight < 750 ? 250 : 400 }}
    >
      <div className='flex justify-between items-center px-8 pt-8 pb-1'>
        <Paragraph className='flex items-center truncate' size={paragraphSizes.large} weight={fontWeights.medium}>
          <span className='truncate max-w-xs'>{translate('card.latestVisitors.recordingsVisitorActions.title')}</span>
          <span>{` (${addNumberSeparator(regularEventsTotal, locale)})`}</span>
          <span className='mx-1'>·</span>
          <span className='flex items-center gap-1 px-[3px]'>
            <NegativeTagIcon color='#f06651' />
            <span className='text-red-sienna'>{addNumberSeparator(alarmingEventsTotal, locale)}</span>
          </span>
        </Paragraph>
        <CloseButton onClose={() => context.onOpenChange(false)} />
      </div>

      <div className='grow scrollbar scrollbar-thumb px-30 mb-30 overflow-y-auto'>
        <div className='flex items-center gap-10 justify-between mb-3'>
          <ParagraphWithTooltip size={paragraphSizes.tiny} weight={fontWeights.medium}>
            {translate('card.latestVisitors.recordingsVisitorActions.eventsTitle')}
          </ParagraphWithTooltip>
          <ParagraphWithTooltip size={paragraphSizes.tiny} weight={fontWeights.medium}>
            {translate('text.total')}
          </ParagraphWithTooltip>
        </div>

        <ul className='space-y-2'>
          {eventEntries.map(([eventType, count]) => (
            <VisitorActionListItem
              key={eventType}
              translationKey={actionsTranslations[eventType]}
              count={count}
              icon={
                <IconWithBackground
                  className={eventType in alarmingEvents ? 'bg-red-negative' : 'bg-black-dark'}
                  icon={() => (eventType in alarmingEvents ? <NegativeTagIcon color='white' /> : iconMap[eventType])}
                />
              }
            />
          ))}
        </ul>
      </div>
    </div>
  );
}
