import { TEST_IDS } from '@va/constants';
import { setHighlightedRecordingId } from '@va/dashboard/actions/app';
import { setRecordingsOverviewInitialFilters } from '@va/dashboard/actions/ui';
import {
  MappedEventType,
  RecordingEvent,
  RecordingsDetailsContextProvider,
  RecordingsVideoPlayerV2,
} from '@va/dashboard/modules/recordings/details';
import { useRecordingsFilterOptions } from '@va/dashboard/modules/recordings/main';
import {
  RecordingsBulkOperations,
  RecordingsContextProvider,
  useMarkSeenRecording,
  useRecordingsColumnsV8,
  useRecordingsContext,
} from '@va/dashboard/shared/recordings';
import { useDataView } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { AddFilterButton, AppliedFilters, FiltersContextProvider } from '@va/shared/feature-filters';
import { AlarmingEventTrigger, RecordingType } from '@va/types/recordings';
import { DataViewOption } from '@va/types/report-block';
import { DataTableV8 } from '@va/ui/components/data-table';
import {
  ReportBlockBody,
  ReportBlockHeader,
  ReportBlockProvider,
  ReportBlockSubHeader,
} from '@va/ui/components/report-block';
import SplitPanel from '@va/ui/components/visualization/SplitPanel';
import { Button, fontWeights, HorizontalScroll, Paragraph } from '@va/ui/design-system';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

const RECORDINGS_WITH_ABES_RB = 'recordings-with-abes-rb';
export const RecordingsWithAbesRb = () => {
  const translate = useTranslate();
  const { filterOptionsWithoutAbes } = useRecordingsFilterOptions();
  const { trigger } = useParams<{ trigger: AlarmingEventTrigger }>();

  const nodes = useMemo(
    () => ({
      filterButton: <AddFilterButton />,
      appliedFilters: <AppliedFilters />,
      bulkActions: <RecordingsBulkOperations />,
      pagination: <div id={RECORDINGS_WITH_ABES_RB} />,
    }),
    [],
  );

  const visualization = useDataView({
    defaultView: DataViewOption.splitTableRecording,
    identifier: RECORDINGS_WITH_ABES_RB,
  });

  return (
    <FiltersContextProvider allFilterOptions={filterOptionsWithoutAbes}>
      <RecordingsContextProvider config={{ initialPageSize: 6, initialFilters: { alarmingEvents: [trigger] } }}>
        <ReportBlockProvider
          id={RECORDINGS_WITH_ABES_RB}
          title={translate('all.alarmingBehaviorEvents.recordingsWithAbes.title')}
          titleTooltip={translate('all.alarmingBehaviorEvents.recordingsWithAbes.titleTooltip')}
          nodes={nodes}
          visualization={visualization}
        >
          <RB />
        </ReportBlockProvider>
      </RecordingsContextProvider>
    </FiltersContextProvider>
  );
};

const RB = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const translate = useTranslate();
  const { trigger } = useParams<{ trigger: AlarmingEventTrigger }>();
  const {
    tableState: { rowSelection },
  } = useRecordingsContext();
  const { alarmingEventFilter } = useRecordingsFilterOptions();

  const selectedRecordingId = useMemo<string | null>(() => Object.keys(rowSelection)[0] ?? null, [rowSelection]);
  const { execute: markSeen } = useMarkSeenRecording([selectedRecordingId!]);

  const openRecordingDetails = useCallback(() => {
    markSeen(true);
    history.push(`/behaviour/recordings/${selectedRecordingId}`, { from: history.location.pathname });
    dispatch(setHighlightedRecordingId(selectedRecordingId));
  }, [dispatch, history, markSeen, selectedRecordingId]);

  const openRecordingsOverview = useCallback(() => {
    history.push('/behaviour/recordings');
    if (alarmingEventFilter) {
      alarmingEventFilter.values = [trigger];
      dispatch(setRecordingsOverviewInitialFilters([alarmingEventFilter]));
    }
  }, [alarmingEventFilter, dispatch, history, trigger]);

  const hideEventInProgressBar = useCallback((event: RecordingEvent) => event.data.source !== trigger, [trigger]);
  const startFromEvent = useCallback((event: MappedEventType) => event.source === trigger, [trigger]);

  return (
    <SplitPanel
      left={<Table />}
      right={
        <HorizontalScroll className='h-full' containerClassName='h-full'>
          <div className='p-30px min-w-[300px] flex flex-col gap-3 h-full'>
            {selectedRecordingId !== 'undefined' && selectedRecordingId ? (
              <RecordingsDetailsContextProvider sessionId={selectedRecordingId}>
                <div>
                  <RecordingsVideoPlayerV2
                    sessionId={selectedRecordingId}
                    hideEventInProgressBar={hideEventInProgressBar}
                    startFromEvent={startFromEvent}
                  />
                </div>
                <Button
                  text={translate('all.alarmingBehaviorEvents.recordingsWithAbes.openRecordingButton')}
                  onClick={openRecordingDetails}
                  data-testid={TEST_IDS.generic.buttons.seeMore}
                />
                <Button
                  color='secondary'
                  text={translate('all.alarmingBehaviorEvents.recordingsWithAbes.openRecordingsOverviewButton')}
                  onClick={openRecordingsOverview}
                  data-testid={TEST_IDS.generic.buttons.viewAll}
                />
              </RecordingsDetailsContextProvider>
            ) : (
              <div className='w-full h-full flex items-center justify-center'>
                <Paragraph weight={fontWeights.medium}>
                  {translate('all.eventTracking.alarmingBehaviorEvents.noRecordingsSelected')}
                </Paragraph>
              </div>
            )}
          </div>
        </HorizontalScroll>
      }
    />
  );
};

const Table = () => {
  const { recordings, isLoading, tableState, totalCount } = useRecordingsContext();
  const columns = useRecordingsColumnsV8();

  return (
    <ReportBlockBody border={false} className={'min-h-[500px]'}>
      <ReportBlockHeader />
      <ReportBlockSubHeader />
      <DataTableV8<RecordingType>
        id='abe-recordings-table'
        paginationContainerId={RECORDINGS_WITH_ABES_RB}
        isLoading={isLoading}
        data={isLoading ? [] : recordings}
        columns={columns}
        state={{
          pagination: tableState.pagination,
          rowSelection: tableState.rowSelection,
          sorting: tableState.sorting,
        }}
        rowCount={totalCount}
        enableMultiRowSelection={false}
        onRowSelectionChange={tableState.setRowSelection}
        onPaginationChange={tableState.setPagination}
        onSortingChange={tableState.setSorting}
      />
    </ReportBlockBody>
  );
};
