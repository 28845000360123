import { ONE_DAY, pathName } from '@va/constants';
import { PRIVACY_FEATURES, PrivacyLevelsEnum } from '@va/standalone/shared/types';
import Config from 'Config';
import { LocalStorage } from '../storage';

// TODO Moved helpers to dashboard-util-helper
// TODO use imports from constants
const EMBEDDED_VIDEO_TRANS_KEY_PREFIX = 'video.youtube.embed';
const FEATURE_VIDEO_TRANS_KEY_PREFIX = `${EMBEDDED_VIDEO_TRANS_KEY_PREFIX}.tab`;
const FLAG_ICONS_VERSION = '6.15.0';
const STICKY_HEADER_HEIGHT = '400px';
const defaultWebsiteId = '00000000-0000-0000-0000-000000000000';

export function validateVideoEmbedCode(embedCode: string, translate: (k: string) => string | { type: string }) {
  const translation = translate(embedCode);
  return (
    (typeof translation === 'object' && translation !== null && translation.type === 'iframe') ||
    (typeof translation === 'string' && translation !== '' && translation.indexOf('<iframe') !== -1)
  );
}

export function getBaseUrl() {
  const env = (Config && Config.env) || import.meta.env.MODE;
  if (env === 'dev' || env === 'development') {
    return 'https://app.visitor-analytics.io';
  }

  return window.location.origin;
}

export function getFlagUrl(code: string, size: string) {
  if (!code) return;
  const BASE_URL = getBaseUrl() + '/cloudflare/ajax/libs/flag-icon-css';
  return BASE_URL + '/' + FLAG_ICONS_VERSION + '/flags/' + size + '/' + code + '.svg';
}

export function isAppsumoPageSelected() {
  return window.location.pathname === pathName.appsumo;
}

/**
 * @return string
 */
export function getFeatureVideoTransKey(tabName: string, translate: (k: string) => string) {
  const videoEmbedTransKey = FEATURE_VIDEO_TRANS_KEY_PREFIX + `.${tabName}.url`;
  if (validateVideoEmbedCode(videoEmbedTransKey, translate)) return videoEmbedTransKey;
  return '';
}

export function hasFeatureVideo(tabName: string, translate: (k: string) => string) {
  return getFeatureVideoTransKey(tabName, translate) !== '';
}

export function isWixApp() {
  return Config.appType === 'wix';
}

export function isWixMiniApp() {
  return Config.appType === 'wix-mini';
}

export function isStandaloneApp() {
  return Config.appType === 'standalone';
}

export function is3asApp() {
  return Config.appType === 'aaas';
}

export function getManageConsentsRouteByApp() {
  switch (Config.appType) {
    case 'wix':
    case 'standalone':
      return pathName.consent;
    case 'website-viewer':
      return '/settings/consent';
    default:
      return '/settings/consent';
  }
}

export function isVisitorAnalyticsUrl(url: string) {
  const urlObject = new URL(url);
  const allowedOrigins = [
    'https://www.visitor-analytics.io',
    'https://visitor-analytics.io',
    'https://www.twipla.com',
    'https://twipla.com',
  ];
  return allowedOrigins.includes(urlObject.origin);
}

export function handleAAASQueryParams() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const queryParameters = Object.fromEntries(urlSearchParams.entries());
  const { aaasCss, aaasImg, aaasJs } = queryParameters;

  if (aaasImg && isVisitorAnalyticsUrl(aaasImg)) {
    const style = `
      #app-logo svg {
        display: none;
      }

      #app-logo {
        height: 30px;
        width: 250px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(${aaasImg});
        background-size: contain;
      }
    `;

    const logoStyle = document.createElement('style');
    logoStyle.innerText = style;
    document.body.appendChild(logoStyle);
  }

  if (aaasCss && isVisitorAnalyticsUrl(aaasCss)) {
    const linkElement = document.createElement('link');
    linkElement.type = 'text/css';
    linkElement.rel = 'stylesheet';
    linkElement.href = aaasCss;
    document.body.appendChild(linkElement);
  }

  if (aaasJs && isVisitorAnalyticsUrl(aaasJs)) {
    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.src = aaasJs;
    scriptElement.defer = true;
    document.body.appendChild(scriptElement);
  }
}

export function getTypo3LocaleQueryParamValue(locale: string = 'en') {
  return `${locale}${locale && locale.toLowerCase() !== 'en' ? ',en' : ''}`;
}

export const getResponsiveTooltipHeight = (windowHeight: number) => {
  if (windowHeight < 800) return `calc(100vh - var(--sticky-header-height))`;
  return 'auto';
};

export const isDefaultWebsiteId = (websiteId: string) => websiteId === defaultWebsiteId;

export function getViewOnWixUrl(metaSiteId: string) {
  if (Config && Config.wixAppId && metaSiteId) {
    return `https://www.wix.com/dashboard/${metaSiteId}/app/${Config.wixAppId}/`;
  }

  return `https://wix.com`;
}

export function shouldShowAskForReview(firstLogin: boolean, reviewStatus: boolean) {
  try {
    const laterReview = Number(LocalStorage.getItem('laterReview'));
    const now = Math.round(new Date().getTime() / 1000);
    // If the user have previously clicked the Later btn,
    // we check if it has passed 2 days in order to show the review notification again.
    return (
      Config.showAskForReview !== false &&
      firstLogin &&
      reviewStatus !== false &&
      (laterReview ? now - laterReview > ONE_DAY * 2 : true)
    );
  } catch (err) {
    return false;
  }
}

export function getOriginUrl(url: string) {
  try {
    return new URL(url).origin;
  } catch (error) {
    return url;
  }
}

export function appVersion() {
  return Config?.sourceTag ?? 'not defined';
}

export function logVersion() {
  console.log(`Current version is ${appVersion()}.`);
}

export function privacyLevelFeatures(level: PrivacyLevelsEnum) {
  switch (level) {
    case PrivacyLevelsEnum.defaultPrivacy:
      return [
        PRIVACY_FEATURES.ipAddressTracking,
        PRIVACY_FEATURES.individualPageHistory,
        PRIVACY_FEATURES.returningVisitorTracking,
        PRIVACY_FEATURES.screenResolutionTracking,
      ];

    case PrivacyLevelsEnum.basicPrivacy:
      return [
        PRIVACY_FEATURES.individualPageHistory,
        PRIVACY_FEATURES.returningVisitorTracking,
        PRIVACY_FEATURES.screenResolutionTracking,
      ];
    case PrivacyLevelsEnum.GDPRMode:
      return [PRIVACY_FEATURES.returningVisitorTracking, PRIVACY_FEATURES.screenResolutionTracking];
    default:
      return false;
  }
}
