export type AlarmingEvents = {
  rageClicks?: number;
  deadClicks?: number;
  mouseMoves?: number;
  scrolls?: number;
  refreshes?: number;
  uTurns?: number;
};

export type Events = Pick<EventsData, 'clickInteractions' | 'moveInteractions' | 'scrollInteractions'>;

export type EventsData = {
  clickInteractions: number;
  events: number;
  incSnapshots: number;
  moveInteractions: number;
  scrollInteractions: number;
};

export const alarmingEventsMap = {
  rageClicks: 'rageClicks',
  deadClicks: 'deadClicks',
  mouseMoves: 'mouseMoves',
  scrolls: 'scrolls',
  refreshes: 'refreshes',
  uTurns: 'uTurns',
};

export const regularEventsMap = {
  clickInteractions: 'clickInteractions',
  moveInteractions: 'moveInteractions',
  scrollInteractions: 'scrollInteractions',
};

export const alarmingEventsTranslations = {
  [alarmingEventsMap.rageClicks]: 'all.eventTracking.alarmingBehaviorEvents.triggers.rageClicks',
  [alarmingEventsMap.deadClicks]: 'all.eventTracking.alarmingBehaviorEvents.triggers.deadClicks',
  [alarmingEventsMap.mouseMoves]: 'all.eventTracking.alarmingBehaviorEvents.triggers.mouseMoves',
  [alarmingEventsMap.scrolls]: 'all.eventTracking.alarmingBehaviorEvents.triggers.scrolls',
  [alarmingEventsMap.refreshes]: 'all.eventTracking.alarmingBehaviorEvents.triggers.refreshes',
  [alarmingEventsMap.uTurns]: 'all.eventTracking.alarmingBehaviorEvents.triggers.uTurns',
};

export const regularEventsTranslations = {
  [regularEventsMap.clickInteractions]: 'sessionRecordings.visitorsActions.events.clickInteractions',
  [regularEventsMap.moveInteractions]: 'sessionRecordings.visitorsActions.events.moveInteractions',
  [regularEventsMap.scrollInteractions]: 'sessionRecordings.visitorsActions.events.scrollInteractions',
};
