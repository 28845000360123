import { defaultWebsiteId } from '@va/constants';
import { dataViewerRoutes, storageItems } from '@va/standalone/shared/constants';
import { isRefreshTokenExpired, LocalStorage } from '@va/util/helpers';
import { createBrowserHistory, LocationDescriptor } from 'history';
import { isNil, isString } from 'lodash';

const history = createBrowserHistory({
  getUserConfirmation: () => {
    /* Empty callback to block the default browser prompt */
  },
});
const initialPushFn = history.push.bind({});
const initialReplaceFn = history.replace.bind({});

const handleChangePathForDataViewerRoutes = (
  initialFn: typeof initialPushFn,
  path: LocationDescriptor<unknown>,
  state: unknown,
  keepQueryParams = true,
  keepHash = true,
) => {
  const activeWebsiteId = LocalStorage.getItem(storageItems.lastWebsiteId) || defaultWebsiteId;

  const { pathname, queryParams, anchor } = splitPath(path);
  let newPath = pathname ?? '/';

  if (isNil(pathname)) {
    return initialFn(path, state);
  }

  if (
    (dataViewerRoutes.includes(pathname) ||
      /^\/ecommerce\/./i.test(pathname) ||
      /^\/dashboard\/custom-dashboard\/./i.test(pathname) ||
      /^\/visitors\/history\/./i.test(pathname) ||
      /^\/recordings\/history\/./i.test(pathname) ||
      /^\/campaigns\/history\/./i.test(pathname) ||
      /^\/feedback\/./i.test(pathname) ||
      /^\/behaviour\/recordings\/./i.test(pathname) ||
      /^\/behaviour\/funnels\/./i.test(pathname) ||
      /^\/behaviour\/heatmaps\/./i.test(pathname) ||
      /^\/behaviour\/event-tracking\/alarming-behavior-events\/./i.test(pathname)) &&
    !isRefreshTokenExpired()
  ) {
    newPath = `/website/${activeWebsiteId}${pathname && pathname[0] !== '/' ? '/' : ''}`.concat(
      pathname,
      keepQueryParams ? queryParams : '',
    );
  } else if (keepQueryParams) {
    newPath = pathname.concat(queryParams);
  }

  if (keepHash && anchor) {
    newPath = newPath + anchor;
  }

  return initialFn(newPath, state);
};

// @ts-ignore
history.push = (path: LocationDescriptor<unknown>, state: unknown, keepQueryParams: boolean, keepHash: boolean) => {
  return handleChangePathForDataViewerRoutes(initialPushFn, path, state, keepQueryParams, keepHash);
};
// @ts-ignore
history.replace = (path: LocationDescriptor<unknown>, state: unknown, keepQueryParams: boolean) => {
  return handleChangePathForDataViewerRoutes(initialReplaceFn, path, state, keepQueryParams);
};

export default history;

type SplitPath = {
  pathname: string;
  queryParams: string;
  anchor: string;
};

export function splitPath(path: LocationDescriptor<unknown> | string): SplitPath {
  if (isString(path)) return splitStringIntoComponents(path);

  return {
    pathname: path.pathname ?? window.location.pathname,
    queryParams: path.search ?? '',
    anchor: path.hash ?? '',
  };
}

export function splitStringIntoComponents(fullPath: string): SplitPath {
  const [pathname, rest] = fullPath.split('?');

  if (!rest) return { pathname, queryParams: '', anchor: '' };

  const [queryParams, anchor] = rest.split('#');

  return { pathname, queryParams: `?${queryParams}`, anchor: anchor && `#${anchor}` };
}
