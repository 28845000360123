import { Events, EventsData } from '@va/types/events';
import { RecordingType } from '@va/types/recordings';

export function subtractEventsFromData(eventsData: EventsData): Events {
  return {
    clickInteractions: eventsData.clickInteractions,
    moveInteractions: eventsData.moveInteractions,
    scrollInteractions: eventsData.scrollInteractions,
  };
}

export function sumObjectValues(obj: Record<string, number>): number {
  return Object.values(obj).reduce((prev, current) => prev + current, 0);
}

export function calculateTotalAlarmingEvents(data: RecordingType[]): number {
  return data.reduce((sum, row) => sum + sumObjectValues(row.alarmingEvents), 0);
}

export function calculateTotalEvents(data: RecordingType[]): number {
  return data.reduce((sum, row) => sum + sumObjectValues(subtractEventsFromData(row.events)), 0);
}
