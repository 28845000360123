import { Direction } from '@va/types/table';
import { Order } from '../types/types';

export const orderMap = {
  count: 'count',
  percentOfTotal: 'percentOfTotal',
};
export const AllowedOrders = Object.values(orderMap);

export const DEFAULT_ORDER: Order[] = [
  {
    member: orderMap.count,
    direction: Direction.DESC,
  },
];
