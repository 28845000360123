import { SortingState } from '@tanstack/react-table';
import { Direction } from '@va/types/table';
import { CHART_COLORS_ARRAY } from '@va/ui/design-system';
import { AllowedOrders } from '../data/order';
import { AlarmingBehaviorResponse, Order } from '../types/types';

export function mapAlarmingBehaviorResponse(response: AlarmingBehaviorResponse): AlarmingBehaviorResponse {
  return {
    ...response,
    payload: response.payload.map((behavior, index) => ({
      ...behavior,
      color: CHART_COLORS_ARRAY[index],
    })),
  };
}
export function sortByMapper(sortBy: SortingState | undefined): Order[] | undefined {
  if (!sortBy || sortBy.length === 0) return undefined;

  return sortBy
    .filter(({ id }) => AllowedOrders.includes(id))
    .map(({ id, desc }) => ({ member: id, direction: desc ? Direction.DESC : Direction.ASC }));
}
