import { FilterOperators } from '@va/types/filters';
import { Group } from '../types/types';

export const pageSize = 6;
export const graphType = 'time-series';
export const DEFAULT_GROUP: Group = 'trigger';

export const countComparisonOperators = [
  FilterOperators.equal,
  FilterOperators.greaterThan,
  FilterOperators.greaterThanOrEqual,
  FilterOperators.lowerThan,
  FilterOperators.lowerThanOrEqual,
];

export const percentOfTotalComparisonOperators = [
  FilterOperators.greaterThan,
  FilterOperators.greaterThanOrEqual,
  FilterOperators.lowerThan,
  FilterOperators.lowerThanOrEqual,
];

export const textOperators = [FilterOperators.contains, FilterOperators.doesNotContain, FilterOperators.is];
