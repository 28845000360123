import { createColumnHelper } from '@tanstack/react-table';
import { DetailsCell, DurationCell, ReferrerCell, VisitorDetailsCell } from '@va/dashboard/components';
import { useLocalizationContext } from '@va/localization';
import { RecordingType } from '@va/types/recordings';
import { HeaderCellV8 } from '@va/ui/components/data-table';
import { ButtonColors } from '@va/ui/design-system';
import { addNumberSeparator, isAbesFeatureEnabled, isWixMiniApp } from '@va/util/helpers';
import { useMemo } from 'react';
import { ActionsCell, IdAndDateCell, PagesCell } from './cells';
import { VisitorActionsCell } from './cells/VisitorActionsCell';
import { calculateTotalAlarmingEvents, calculateTotalEvents, subtractEventsFromData } from './helpers/visitorActions';
import { VisitorAlarmingActionCount } from './VisitorAlarmingActionCount';

type ColumnStyle = {
  squareIcons?: boolean;
  buttonColor?: ButtonColors;
  recordingsIconColor?: string;
  recordingsIconWrapper?: string;
  size?: 'small' | 'medium';
};

const columnStyles: Record<'default' | 'wix-mini', ColumnStyle> = {
  default: {},
  'wix-mini': {
    recordingsIconColor: 'var(--color-primary)',
    squareIcons: true,
    buttonColor: 'secondary',
    recordingsIconWrapper: '!bg-primary-lighter-06',
    size: 'small',
  },
};

const columnHelper = createColumnHelper<RecordingType>();

export const useRecordingsColumnsV8 = (variant: 'default' | 'wix-mini' = 'default') => {
  const { locale, translate } = useLocalizationContext();

  const visitorActionsColumn = useMemo(
    () =>
      columnHelper.group({
        minSize: 80,
        id: 'visitorActions',
        header: (ctx) => {
          const data = ctx.table.getRowModel().rows.map((row) => row.original);
          const totalAlarmingEvents = calculateTotalAlarmingEvents(data);
          const totalEvents = calculateTotalEvents(data);
          return (
            <HeaderCellV8
              context={ctx}
              text={translate('tableHeaders.visitorActions', { count: addNumberSeparator(totalEvents, locale) })}
              tooltip={translate('all.recordings.table.headerTooltips.visitorActions')}
              additionalInfo={<VisitorAlarmingActionCount count={totalAlarmingEvents} />}
            />
          );
        },
        cell: (props) => {
          const { alarmingEvents, events } = props.row.original;
          const regularEvents = subtractEventsFromData(events);
          return <VisitorActionsCell alarmingEvents={alarmingEvents} regularEvents={regularEvents} />;
        },
      }),
    [locale, translate],
  );

  // TODO Always check WiX Mini App when altering minSize values
  const allColumns = useMemo(
    () => [
      columnHelper.group({
        id: 'id',
        minSize: 200,
        header: (ctx) => (
          <HeaderCellV8
            context={ctx}
            text={translate('tableHeaders.recordingIdAndDate')}
            tooltip={translate('all.recordings.table.headerTooltips.idAndDate')}
            size={columnStyles[variant].size}
          />
        ),
        cell: (props) => {
          const {
            id,
            sessionStart,
            sessionExtras: { star, seen },
            comment,
          } = props.row.original;
          return (
            <IdAndDateCell
              note={comment?.text}
              recordingId={id}
              date={sessionStart}
              star={star}
              seen={seen}
              iconColor={columnStyles[variant].recordingsIconColor}
              iconWrapperClassName={columnStyles[variant].recordingsIconWrapper}
              size={columnStyles[variant].size}
            />
          );
        },
      }),
      columnHelper.accessor('ssrInfo.duration', {
        id: 'duration',
        minSize: 90,
        header: (ctx) => (
          <HeaderCellV8
            context={ctx}
            text={translate('tableHeaders.recordingDuration')}
            tooltip={translate('all.recordings.table.headerTooltips.recordingDuration')}
            size={columnStyles[variant].size}
          />
        ),
        cell: (props) => <DurationCell duration={props.getValue()} size={columnStyles[variant].size} />,
      }),
      columnHelper.accessor('ssrInfo.pagesCount', {
        minSize: 190,
        header: (ctx) => (
          <HeaderCellV8
            context={ctx}
            text={translate('tableHeaders.pages')}
            tooltip={translate('all.recordings.table.headerTooltips.pages')}
            size={columnStyles[variant].size}
          />
        ),
        cell: (props) => {
          const {
            entryPage,
            exitPage,
            ssrInfo: { pagesCount, pages },
            countryCode,
            id,
          } = props.row.original;

          return (
            <PagesCell
              sessionId={id}
              entryPage={entryPage}
              exitPage={exitPage}
              pages={pages}
              totalCount={pagesCount}
              countryCode={countryCode}
              size={columnStyles[variant].size}
              isClickable={variant === 'default'}
            />
          );
        },
      }),
      columnHelper.group({
        id: 'visitorDetails',
        minSize: 140,
        header: (ctx) => (
          <HeaderCellV8
            context={ctx}
            text={translate('tableHeaders.visitorDetails')}
            tooltip={translate('all.recordings.table.headerTooltips.visitorDetails')}
            size={columnStyles[variant].size}
          />
        ),
        cell: (props) => {
          const { status, countryCode, location } = props.row.original;
          return (
            <VisitorDetailsCell
              location={location}
              visitorType={status}
              countryCode={countryCode}
              size={columnStyles[variant].size}
              squareIcon={columnStyles[variant].squareIcons}
              isClickable={variant === 'default'}
            />
          );
        },
      }),
      columnHelper.accessor('referrer', {
        minSize: 160,
        header: (ctx) => (
          <HeaderCellV8
            context={ctx}
            text={translate('tableHeaders.referrer')}
            tooltip={translate('all.recordings.table.headerTooltips.referrer')}
            size={columnStyles[variant].size}
          />
        ),
        cell: (props) => (
          <ReferrerCell
            referrer={props.getValue()}
            size={columnStyles[variant].size}
            squareIcon={columnStyles[variant].squareIcons}
          />
        ),
      }),
      columnHelper.group({
        id: 'details',
        minSize: 160,
        header: (ctx) => (
          <HeaderCellV8
            context={ctx}
            text={translate('tableHeaders.recordingDetails')}
            tooltip={translate('all.recordings.table.headerTooltips.recordingDetails')}
            size={columnStyles[variant].size}
          />
        ),
        cell: (props) => {
          const { browser, platform, device } = props.row.original;
          return (
            <DetailsCell
              browser={browser}
              platform={platform}
              device={device}
              size={columnStyles[variant].size}
              squareIcon={columnStyles[variant].squareIcons}
            />
          );
        },
      }),
      columnHelper.display({
        id: 'actions',
        minSize: 100,
        header: (ctx) => (
          <HeaderCellV8
            context={ctx}
            text={translate('tableHeaders.actions')}
            tooltip={translate('all.recordings.table.headerTooltips.actions')}
            size={columnStyles[variant].size}
          />
        ),
        cell: (props) => {
          const {
            id,
            comment,
            sessionExtras: { seen, star },
          } = props.row.original;
          return (
            <ActionsCell
              seenButtonColor={columnStyles[variant].buttonColor}
              star={star}
              hasComment={!!comment}
              recordingId={id}
              seen={seen}
              size={columnStyles[variant].size}
            />
          );
        },
      }),
    ],
    [translate, variant],
  );

  return useMemo(() => {
    if (!isWixMiniApp() && isAbesFeatureEnabled()) {
      allColumns.splice(2, 0, visitorActionsColumn);
    }
    return allColumns;
  }, [allColumns, visitorActionsColumn]);
};
