import { TooltipWrapper } from '@va/ui/tooltips';
import { formatSessionDuration } from '@va/util/helpers';
import { FC } from 'react';

import { getEventIcon } from '@va/shared/ssr-player';
import { Paragraph, paragraphSizes } from '@va/ui/design-system';

type ProgressBarEvent = {
  delay: number;
  details: string;
  color: string;
  name: string;
};

type ProgressBarEventProps = {
  eventData: ProgressBarEvent;
  totalTime: number;
};

const ProgressBarEvent: FC<ProgressBarEventProps> = ({ eventData, totalTime }) => {
  const left = `${(100 * eventData.delay) / totalTime}%`;

  return (
    <TooltipWrapper
      tooltipClassNames={'rounded-10.5 px-18px py-10.5 bg-black-lighter-84'}
      useDefaultStyle={false}
      content={
        <div className={'flex-col text-center'}>
          <Paragraph colorClassName={'text-white'} size={paragraphSizes.tiny}>
            {formatSessionDuration(eventData.delay)}
          </Paragraph>
          <div style={{ color: eventData.color }} className={'flex flex-row items-center gap-1'}>
            <div>
              {getEventIcon({
                eventDetails: eventData.name,
                color: eventData.color,
              }).icon()}
            </div>
            {eventData.details}
          </div>
        </div>
      }
      placement={'top'}
    >
      <div className={'ssr-user-event'} style={{ left, backgroundColor: eventData.color }} />
    </TooltipWrapper>
  );
};

export default ProgressBarEvent;
